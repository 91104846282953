.fondo {
    position: fixed;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: pointer;
}

.fondo::-webkit-scrollbar {
    display: none;
}

.contenido-perfil {
    margin: 5% auto;
    background-color: rgba(0,0,0,0.85);
    color: white;
    border-radius: 0.5rem;
    width: 80vw;
    padding: 2rem;
    position: relative;
}
