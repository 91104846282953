@charset "UTF-8";

/* Slider */

.slick-slider {
  position: relative;
  display: block !important;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

// Default Variables
$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: rgb(77, 77, 77) !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '◄' !default;
$slick-next-character: '►' !default;
$slick-dot-character: '•' !default;
// $slick-dot-size: 40px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 0.9 !default;
$slick-opacity-not-active: 0.3 !default;

/* Slider */

// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
//     }
// }

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  font-size: 0;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    background-color: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-size: 1.25rem;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
  }
}

.slick-prev {
  left: -25px;
  [dir='rtl'] & {
    right: -1.5rem;
  }
  &:before {
    content: $slick-prev-character;
    [dir='rtl'] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;
  [dir='rtl'] & {
    right: auto;
    left: -1.5rem;
  }
  &:before {
    content: $slick-next-character;
    [dir='rtl'] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  // @apply mb-7;
  margin-bottom: 1.75rem;
}

.slick-dots {
  position: absolute;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  bottom: 1rem;
  width: 100%;
  list-style: none;
  li {
    position: relative;
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
    button {
      border: 0;
      background-color: transparent;
      display: block;
      height: 1.25rem;
      width: 1.25rem;
      outline: none;
      color: transparent;
      padding: 0.25rem;
      cursor: pointer;
      line-height: 0;
      font-size: 0;
      &:hover,
      &:focus {
        transition: all;
        outline: none;
        &:before {
          opacity: 0.95;
        }
      }
      &:before {
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        font-size: 2rem;
        text-align: center;
        content: $slick-dot-character;
        line-height: 20px;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.slick_arrow_next {
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: 0;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 999px;
}

.slick_arrow_prev {
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 999px;
}

.slick-slide {
  visibility: hidden;
}
.slick-slide.slick-active {
  visibility: visible;
}

@media (max-width: 768px) {
  .slick_arrow_next {
    display: none;
  }
  .slick_arrow_prev {
    display: none;
  }
}
