/* ----------------------------------------------------------------
	Common
-----------------------------------------------------------------*/

.bulletin {
  color: #262426;
  background: #f6f9fc;
  display: flex;
  align-items: center;

  &-button-container {
    display: flex;
    justify-content: center;
    grid-column: 1 / span 2;
  }

  &-button {
    border: none;
    padding: 16px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    background: #b32317;
    border-radius: 32px;
    color: #ffffff;
    width: 100%;
    max-width: 325px;
  }

  &-container {
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    max-width: 1440px;
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-template-rows: auto;
  }

  h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  a {
    text-decoration: underline !important;
    color: inherit !important;
  }

  &-details {
    max-width: 430px;
    margin: 100px auto;
  }

  &-card {
    font-family: 'Nunito Sans';
    font-style: normal;
    background: #ffffff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin-top: 25px;
    margin-bottom: -25px;
    margin-left: 10px;
    padding: 42px 24px;
    max-width: 555px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    label {
      font-family: inherit;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: #272833;
      display: flex;
      flex-direction: column;
    }

    input {
      border: 1px solid #e6ebf1;
      border-radius: 4px;
      height: 40px;
      margin-top: 8px;
      padding: 0 8px;
    }
  }

  &-error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
}

/* ----------------------------------------------------------------
	Tablet - Mobile
-----------------------------------------------------------------*/

@media (max-width: 1023px) {
  .bulletin-container {
    grid-template-columns: 1fr;
  }
  .bulletin-card-container {
    justify-self: center;
  }
  .bulletin-card {
    min-width: 555px;
    margin-top: 0;
    margin-left: 0;
  }
  .bulletin-details {
    max-width: 524px;
    margin: 28px auto 48px;
    text-align: center;
    p {
      margin: 0;
    }
  }
  .bulletin-button {
    margin-top: 8px;
  }
}

/* ----------------------------------------------------------------
	Mobile
-----------------------------------------------------------------*/

@media (max-width: 767px) {
  .bulletin-card-container {
    width: 100%;
  }
  .bulletin-card {
    max-width: auto;
    min-width: auto;
    margin: 0 auto 32px;
    display: block;
    padding: px 20px;
  }
  .bulletin-details {
    max-width: auto;
    text-align: left;
    margin: 24px auto;
  }
  label {
    margin-bottom: 24px;
  }
}

/* ----------------------------------------------------------------
	Desktop
-----------------------------------------------------------------*/

@media (min-width: 1024px) {
}
