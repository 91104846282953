$primary-color: #b32017;

html {
  scroll-behavior: smooth;
}

.scrollUp_btn {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  border-radius: 999px;
  color: #ffffff;
  background: $primary-color;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.scrollUp_btn--show {
  opacity: 1;
}
.scrollUp_btn--hidde {
  opacity: 0;
  visibility: hidden;
}
