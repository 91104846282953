.no-background {
  background: none;
}

.no-decorations {
  text-underline: none;
  outline: none;
  &:after,
  &:focus,
  &:active,
  &:hover {
    outline: none;
    text-underline: none;
    text-decoration: none;
  }
}

.fc-event {
  cursor: pointer;
}

.evento-fecha {
  position: absolute;
  top: 28px;
  left: 28px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 64px;
  height: 64px;
  text-align: center;
  padding-top: 8px;
  font-size: 1.7rem;
  line-height: 1.2;
  display: block;
}

.evento-fecha > span {
  display: block;
  font-size: 1rem;
}

.pointer {
  cursor: pointer;
}

.noticia-mes {
  cursor: pointer;
  &:hover {
    color: $danger;
    font-weight: bold;
  }
}

.dots {
  height: 100px;
  background-image: url('./../../assets/images/common/dots.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100px 100px;
  padding-left: 35px;
  display: inline-flex;
  align-items: center;
  margin-left: -35px;
  &.dots-white {
    background-image: url('./../../assets/images/common/dots2.png');
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.year-selector {
  text-align: center !important;
  cursor: pointer;
  border-radius: 2.25rem !important;
  border: 0;
  //background-image: linear-gradient(90deg, #EF0107 0%, #EF0107 35%, #EF0107 100%);
  background-image: radial-gradient(circle, #f9f9f9 0%, #adadad 100%);
}

.bg-search-news {
  background-color: #ececec;
  &:focus {
    background-color: #ececec;
  }
}

// TIPOGRAFIA

.emphasis-title {
  margin: 0;
  & h2 {
    font-size: 40px;
  }
  & h4 {
    margin: 0;
  }
  & p {
    margin: 0;
  }
}

.vdivider {
  border-right: 1px solid dimgray;
}

.text-black {
  color: black !important;
}

.text-red {
  color: $theme-color !important;
}

.text-green {
  color: green !important;
}

.recibo-pago {
  font-weight: bold;
  color: limegreen;
  border-bottom: 2px solid limegreen;
  display: inline-block;
  padding-bottom: 2px;
}

.year-selector {
  .card-header {
    padding: 0.25rem 1.25rem;
  }
  .card-body {
    padding: 0;
  }
}

.faq {
  .card-header {
    padding: 0.5rem 0.8rem;
  }
}

.faq-tabs {
  font-weight: bold;
}
.faq-tabs > .active {
  color: white !important;
  background-color: #c80021 !important;
}

.informe {
  //width: 16rem;
  text-align: center;
  .card-body {
    padding: 0.5rem;
    align-items: center;
  }
  .card-title {
    margin: 0;
  }
}

.material-card {
  text-align: center;
  .card-body {
    padding: 0.5rem;
    align-items: center;
  }
  .card-title {
    margin: 0;
  }
}

.home-product--cke5:before {
  //background-image: url(../images/home/home-product-bg-cke5-ed5d25287b.svg);
  min-height: 616px;
}
.home-product--image-left:before {
  background-position: center left;
}
.home-product:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 1738px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center left;
  transform: translateX(-50%);
}

.equipo-ceead {
  max-width: 300px;
  cursor: pointer;
}

::-moz-selection {
  background: #338fff !important;
}
::selection {
  background: #338fff !important;
}

// Biblioteca Digital

.bd {
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.bd-active {
  color: $theme-color;
  font-weight: bold;
}

.invalid-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.blog-post {
  p {
    margin-bottom: 0;
  }
}

.rounded-xlg {
  border-radius: 0.8rem !important;
}

//Proximos eventos

// .proximos-eventos{
//   &__card{
//     box-shadow: 3px 5px 19px 0px rgba(0,0,0,0.2);
//   }
// }

.font-sm {
  font-size: small;
}

.zIndexDefault {
  div {
    z-index: 0;
    position: inherit;
  }
}
.invalidInputform {
  border-color: #dc3545;
  &:focus,
  &:active {
    // border: 2px solid #dc3545 !important;
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.blog-post {
  .post {
    h1 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    h2 {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 1.5rem;
    }
    table {
      table-layout: fixed;
      width: 100%;
      margin-bottom: 1.5rem;
    }
    th,
    td {
      border: 1px solid #0a5395;
      word-wrap: break-word;
      padding: 1rem;
    }
    th {
      background-color: #3d84c7;
      color: white;
    }
  }
  .blog-quote {
    background-color: #414558;
    padding: 2rem;
    color: #f9f9f9;
    display: grid;
    gap: 1.5rem;
    grid-auto-flow: column;
    margin-bottom: 1.5rem;
    border-radius: 0.375rem;
    p {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .blog-quote {
      grid-auto-flow: row;
    }
    .post {
      table {
        table-layout: inherit;
      }
    }
  }
  .chatgpt {
    background-color: #19c37d;
    padding: 5px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ql-editor {
  white-space: normal !important;
}
