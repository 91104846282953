@import "../variables";@import "../mixins";
/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/
$client-prefix: clients-grid;

.#{$client-prefix} {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	list-style: none;
	overflow: hidden;
	.grid-item {
		position: relative;
		-webkit-backface-visibility: hidden;
		padding: $clients-grid-padding 0;
		-webkit-backface-visibility: hidden;
		a {
			opacity: $clients-img-opacity;
			display: block;
			width: 75%;
			margin-left: auto;
			margin-right: auto;
			&:hover {
				opacity: 1;
			}
			img {
				width: 100%;
			}
			body:not(.device-touch) & {
				-webkit-transition: all .5s ease;
				-o-transition: all .5s ease;
				transition: all .5s ease;
			}
		}
		img {
			display: block;
			width: 75%;
			margin-left: auto;
			margin-right: auto;
		}
		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 0;
			top: auto;
			left: 0;
			bottom: -$clients-grid-border-size;
			border-bottom: $clients-grid-border-size $clients-grid-border-style lighten($text-color, 60%);
		}
	}
}