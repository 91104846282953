
/* ----------------------------------------------------------------
	Toast Notifications
-----------------------------------------------------------------*/


.toast{
	background-color: $toast-bacground-color;
	&-title {
		font-weight: bold;
	}
	&-message {
		-ms-word-wrap: break-word;
		word-wrap: break-word;
		a,
		label {
			color: $toast-color;
		}
		a:hover {
			color: $toast-hover-color;
			text-decoration: none;
		}
	}
	&-close-button {
		position: relative;
		top: -$toast-close-offset;
		right: -$toast-close-offset;
		float: right;
		font-size: $toast-close-font-size;
		font-weight: bold;
		color: $toast-color;
		-webkit-text-shadow: 0 1px 0 $toast-color;
		text-shadow: 0 1px 0 $toast-color;
		opacity: 0.8;
		-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
		filter: alpha(opacity=80);
		line-height: 1;
		&:hover,
		&:focus {
			color: #000000;
			text-decoration: none;
			cursor: pointer;
			opacity: 0.4;
			-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
			filter: alpha(opacity=40);
		}
		.rtl & {
			left: -$toast-close-offset;
			float: left;
			right: $toast-close-offset;
		}
	}
	/*Additional properties for button version
	 iOS requires the button element instead of an anchor tag.
	 If you want the anchor version, it requires `href="#"`.*/
	@at-root button#{&}-close-button {
		padding: 0;
		cursor: pointer;
		background: transparent;
		border: 0;
		-webkit-appearance: none;
	}
	&-top-center {
		top: 0;
		right: 0;
		width: 100%;
	}
	&-bottom-center {
		bottom: 0;
		right: 0;
		width: 100%;
	}
	&-top-full-width {
		top: 0;
		right: 0;
		width: 100%;
	}
	&-bottom-full-width {
		bottom: 0;
		right: 0;
		width: 100%;
	}
	&-top-left {
		top: $toast-position-gutter;
		left: $toast-position-gutter;
	}
	&-top-right {
		top: $toast-position-gutter;
		right: $toast-position-gutter;
	}
	&-bottom-right {
		right: $toast-position-gutter;
		bottom: $toast-position-gutter;
	}
	&-bottom-left {
		bottom: $toast-position-gutter;
		left: $toast-position-gutter;
	}
	@each $name, $hex in $theme-colors {
		&-#{$name} {
			background-color: $hex;
		}
	}
	.toast-light {
	    color: $text-color;
	}
	&-progress {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 4px;
		background-color: #000000;
		opacity: 0.4;
		-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
		filter: alpha(opacity=40);
	}
}
#toast-container {
	position: fixed;
	z-index: 999999;
	pointer-events: none;
	/*overrides*/
	 * {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	> div {
		position: relative;
		pointer-events: auto;
		overflow: hidden;
		margin: 0 0 6px;
		padding: $toast-container-padding;
		width: $toast-container;
		border-radius: $toast-container-radius;
		background-repeat: no-repeat;
		-moz-box-shadow: 0 0 12px #999999;
		-webkit-box-shadow: 0 0 12px #999999;
		box-shadow: 0 0 12px #999999;
		color: #FFFFFF;
		opacity: 0.8;
		-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
		filter: alpha(opacity=80);
		&.rtl{ direction: rtl; }
		&:hover {
			-moz-box-shadow: 0 0 12px #000000;
			-webkit-box-shadow: 0 0 12px #000000;
			box-shadow: 0 0 12px #000000;
			opacity: 1;
			-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			filter: alpha(opacity=100);
			cursor: pointer;
		}
	}
	&.toast-top-center > div,
	&.toast-bottom-center > div {
		width: $toast-container;
		margin-left: auto;
		margin-right: auto;
	}
	&.toast-top-full-width > div,
	&.toast-bottom-full-width > div {
		width: 96%;
		margin-left: auto;
		margin-right: auto;
	}
}



/*Responsive Design*/
@media all and (max-width: 240px) {
	#toast-container > div {
		padding: 10px 15px;
		width: 11em;
	}
	#toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
	#toast-container .rtl .toast-close-button {
		left: -0.2em;
		right: 0.2em;
	}
}
@media all and (min-width: 241px) and (max-width: 480px) {
	#toast-container > div {
		padding: 10px 15px;
		width: 18em;
	}
	#toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
	#toast-container .rtl .toast-close-button {
		left: -0.2em;
		right: 0.2em;
	}
}
@media all and (min-width: 481px) and (max-width: 768px) {
	#toast-container > div {
		padding: 15px 20px;
		width: 25em;
	}
}