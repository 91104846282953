/* ----------------------------------------------------------------
	Common
-----------------------------------------------------------------*/

footer {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 1440px;

  .copyright p{
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    color: #272833;
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
    margin-bottom: 0;
    li {
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.3px;
      color: #4f5057;
      margin-bottom: 12px;
      a {
        color: #4f5057;
      }
    }
  }

  .social {
    margin-top: 40px;
    margin-bottom: 32px;
    gap: 16px;
    font-size: 20px;
    a {
      color: #4f5057;
      margin-right: 16px;
    }
    :last-child {
      margin-right: 0px;
    }
  }

  h4 {
    font-family: inherit;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #94939b;
  }

  .footer__grid {
    display: grid;

    .links {
      grid-area: enlaces;
    }

    .platform {
      grid-area: plataforma;
    }
    .contact {
      grid-area: contacto;
    }
  }
}

/* ----------------------------------------------------------------
	Mobile
-----------------------------------------------------------------*/

@media (max-width: 767px) {
  footer {
    padding: 32px;
    .footer__grid {
      margin-bottom: 50px;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: auto;
      grid-template-areas:
        'enlaces'
        'plataforma'
        'contacto';
    }
    h4 {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }
}

/* ----------------------------------------------------------------
	Tablet
-----------------------------------------------------------------*/

@media (min-width: 768px) and (max-width: 1023px) {
  footer {
    padding: 48px;
    .footer__grid {
      .links {
        display: flex;
      }

      .platform {
        justify-self: center;
      }

      .contact {
        justify-self: center;
      }

      .logo {
        margin-right: 40px;
      }

      margin-bottom: 50px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-template-areas: 'enlaces plataforma contacto';
    }
  }
}

/* ----------------------------------------------------------------
	Desktop
-----------------------------------------------------------------*/

@media (min-width: 1024px) {
  footer {
    padding: 40px;
    .footer__grid {
      .links {
        display: flex;
      }
      .platform {
        justify-self: center;
      }

      margin-bottom: 100px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      grid-template-areas: 'enlaces plataforma . contacto';

      .logo {
        margin-right: 72px;
      }
    }

    h4 {
      font-family: inherit;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #94939b;
      margin-bottom: 40px;
    }
  }
}
