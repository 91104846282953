/* ----------------------------------------------------------------
	Utils
-----------------------------------------------------------------*/

$background-mobile: #272833;
$background-mobile-dropdown: #323342;
$background: #ffffff;
$light-color: #e6ebf1;
$nav-item: #646679;
$nav-item--active: #272728;

@mixin animate {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}

/* ----------------------------------------------------------------
	Common
-----------------------------------------------------------------*/
.overlay {
  @include animate;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(35, 36, 46, 0.4);
  z-index: 20;
}

.overlay--open {
  overflow: hidden;
}

.header {
  @include animate;
  background: $background;
  display: flex;
  font-family: 'Nunito Sans';
  font-style: normal;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.05);
}

.header__logo img {
  height: 100%;
}

.header-gap {
  height: 60px;
}

.lang-icon {
  font-weight: 700;
  cursor: pointer;
  margin-right: 16px;
}

.header__login-btn {
  align-items: center;
  background: $primary-color;
  border-radius: 40px;
  color: white;
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  padding: 8px 16px;
}
.header__login-btn:hover {
  color: white;
}
.header__login-btn span {
  padding-left: 16px;
}
.header__burguer-menu {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.dropdown__arrow {
  @include animate;
}
.dropdown__arrow--active {
  transform: rotate(-180deg);
}

/* ----------------------------------------------------------------
	Mobile
-----------------------------------------------------------------*/

@media (max-width: 1023px) {
  .header {
    padding: 18px 20px;
  }
  .header__logo {
    height: 24px;
  }
  .lang-icon {
    color: $light-color;
  }
  .header__burguer-menu span {
    margin-left: 12px;
  }
  .aside__header {
    align-items: center;
    color: $light-color;
    display: flex;
    font-size: 16px;
    flex-direction: row-reverse;
    height: 60px;
    justify-content: space-between;
    padding: 16px 20px;
  }
  .aside__menu {
    @include animate;
    background: $background-mobile;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    height: 100vh;
    line-height: 25px;
    max-width: 417px;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 30;
  }
  .aside__menu--hidden {
    transform: translate(100%);
  }
  .aside__menu-nav {
    padding: 16px;
  }
  .aside__menu-nav ul {
    list-style: none;
    margin-bottom: 16px;
  }
  .nav-item {
    border-bottom: 1px solid #3d3e47;
  }
  .nav-item a,
  .nav-item span {
    color: $light-color;
    padding: 16px 0;
  }
  .nav-item > a {
    display: block;
  }
  .nav-dropdown {
    @include animate;
    font-size: 16px;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .nav-dropdown--active {
    height: auto;
    margin-bottom: 16px;
    opacity: 1;
    visibility: visible;
  }
  .nav-dropdown__item a {
    display: block;
    padding: 8px;
  }
  .nav-dropdown ul {
    background: $background-mobile-dropdown;
    border-radius: 8px;
    padding: 4px;
  }
  .dropdown__title-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .aside__footer {
    margin: 40px 20px 20px 20px;
  }
  .aside__register-btn,
  .aside__login-btn {
    display: block;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 16px;
    line-height: 100%;
  }
  .aside__register-btn span {
    margin-right: 8px;
  }
  .aside__login-btn {
    background: $primary-color;
    border-radius: 36px;
    margin-top: 16px;
  }
  .aside__user {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: center;
  }
  .avatar__container {
    align-items: center;
    background: #f5f7f9;
    border-radius: 16px;
    border: 1px solid $primary-color;
    color: #52535c;
    display: flex;
    height: 28px;
    width: 28px;
    justify-content: center;
    margin-right: 12px;
  }
}

/* ----------------------------------------------------------------
	Desktop
-----------------------------------------------------------------*/

@media (min-width: 1024px) {
  .header {
    padding: 24px 40px;
  }
  .header--fixed {
    padding: 14px 40px;
  }
  .header__container ul {
    list-style: none;
  }
  .header__nav > ul {
    display: flex;
    margin: 0 0 0 32px;
  }
  .header__container {
    align-items: center;
    display: flex;
  }
  .header__logo {
    height: 32px;
  }
  .nav-item {
    position: relative;
  }
  .nav-item > a,
  .nav-item > span {
    font-weight: 700;
  }
  .nav-item a,
  .nav-item span {
    color: $nav-item;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    margin-right: 24px;
    position: relative;
  }
  .nav-item a:hover,
  .nav-item span:hover {
    color: $nav-item--active;
  }
  .nav-dropdown {
    @include animate;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    padding-top: 44px;
  }
  .header--fixed .nav-dropdown {
    padding-top: 34px;
  }
  .nav-dropdown::before {
    content: '';
    left: 18px;
    top: 34px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $background;
    clear: both;
    position: absolute;
  }
  .header--fixed .nav-dropdown::before {
    top: 24px;
  }

  .nav-item a:hover + .nav-dropdown,
  .nav-item span:hover + .nav-dropdown,
  .nav-dropdown:hover {
    visibility: visible;
    opacity: 1;
  }
  .nav-dropdown ul {
    background: $background;
    border-radius: 8px;
    padding: 8px;
    width: max-content;
    box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.1);
  }
  .nav-dropdown__item {
    width: 100%;
  }
  .nav-dropdown__item a,
  .nav-dropdown__item span {
    display: block;
    font-weight: 600;
    padding: 8px;
    margin: 0;
  }
}
