//$input-btn-focus-width: 0;

@import '~react-modal-video/scss/modal-video.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~animate.css';
//@import './base/settings';
//@import './base/base';
//@import 'components/accounts';

// Initialize
@import './sass/variables';
@import './sass/mixins';

// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

// Core CSS
@import './sass/layouts';
@import './sass/typography';
@import './sass/utilities';

// Content Blocks
@import './sass/global';
@import './sass/topbar';
@import './sass/header';
@import './sass/sliders';
@import './sass/pagetitle';
@import './sass/content';
@import './sass/portfolio';
@import './sass/blog';
@import './sass/shop';
@import './sass/events';
@import './sass/bulletin';
@import './sass/slick-theme';

// Shortcodes
@import './sass/shortcodes';

// Forms
@import './sass/forms';

// Footer
@import './sass/footer';

// Calendar
@import './sass/calendar';

// Widgets
@import './sass/widgets';

// Paginations
@import './sass/paginations';

// Extras
@import './sass/extras';

// Page Loaders
@import './sass/page-loaders';

// Dark
@import './sass/dark';

// Customizations
@import './sass/custom';
