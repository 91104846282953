
/*-----------------------------------------------------------------------------------

	Shortcodes: alerts.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/
.style-msg,
.style-msg2 {
	position: relative;
	display: block;
	color: $text-color;
	border-left: $alert-left-border solid $alert-left-border-color;
	margin-bottom: 20px;
}

.style-msg2 { border-left-color: $alert-left-border-color; }

@if $theme-colors {
	@each $color, $value in $theme-colors {
	  .#{$color}msg {
	    @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
	  }
	}
	.errormsg {
		@include alert-variant(theme-color-level(("danger"), $alert-bg-level), theme-color-level(("danger"), $alert-border-level), theme-color-level(("danger"), $alert-color-level));;
	}

	.alertmsg {
		@include alert-variant(theme-color-level(("warning"), $alert-bg-level), theme-color-level(("warning"), $alert-border-level), theme-color-level(("warning"), $alert-color-level));;
	}
}

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
	display: block;
	padding: $alert-padding;
	border-left: 1px solid rgba(255,255,255,0.5);
	font-size: $alert-title;
	text-shadow: 1px 1px 1px rgba(255,255,255,0.6);
}

.style-msg2 .msgtitle {
	background: $alert-title-bg;
	border-top: none;
	border-bottom: none;
	font-size: $alert-title + 0.125rem;
	font-weight: bold;
}

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
}

.style-msg2 .sb-msg { font-weight: normal; }

.style-msg2 ol,
.style-msg2 ul { margin: 0 0 0 30px; }

.style-msg2 ol { list-style-type: decimal; }

.style-msg2 ul { list-style-type: disc; }

.alert i,
.sb-msg i,
.msgtitle i {
	position: relative;
	top: 1px;
	font-size: $alert-title;
	width: $alert-title;
	text-align: center;
	margin-right: 5px;
}

.style-msg .close {
	position: absolute;
	top: 10px;
	left: auto;
	right: 10px;
}
