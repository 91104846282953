.fc-toolbar-title {
  color: white;
}

.fc {
  font-size: 1.05em;
}

.fc .fc-scrollgrid-section-sticky > * {
  background: rgba(0, 0, 0, 0.6);
}

.fc-col-header-cell-cushion, .fc-daygrid-day-number {
  color: #000 !important;
}

.fc-scroller-harness {
  background: rgba(255, 255, 255, 0.6);
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.6;
}

.fc-col-header {
  height: 50px;
}

.fc-today-button.btn.btn-primary {
  color: #fff;
  background-color: #959595;
  border-color: #747474;
}

.fc-prev-button.btn-primary, .fc-next-button.btn-primary {
  color: #fff;
  background-color: #a8a8a8;
  border-color: #747474;
}
